const Testimonialdata = [{
    id:1,
    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima dolorem assumenda ex harum enim eaque blanditiis quae? Adipisci deserunt provident a tempora eius, labore rerum!',
    name: '- Lorem ipsum dolor sit.',
    img: "https://whatsapp.hitoritech.com/uploads/23/03/1678130699E0RrnxKNI6tkb6Qc1eV1.jpg"
},
{
    id:2,
    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima dolorem assumenda ex harum enim eaque blanditiis quae? Adipisci deserunt provident a tempora eius, labore rerum!',
    name: '- Lorem ipsum dolor sit.',
    img: "https://whatsapp.hitoritech.com/uploads/23/03/1678130652BHfP85klZ25vaXpBU6AT.jpg"
},
{
    id:3,
    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima dolorem assumenda ex harum enim eaque blanditiis quae? Adipisci deserunt provident a tempora eius, labore rerum!',
    name: '- Lorem ipsum dolor sit.',
    img: "https://whatsapp.hitoritech.com/uploads/23/03/1678130604XsBOBA2JdT4D3dUMsVPO.jpg"
},
]
export default Testimonialdata