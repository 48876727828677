import React from "react";
import './Section3.css'
import Section3Data from "./Section3-data";
import Section3Lay from "./Section3-lay";

const Section3 = ()=>{
  

    return(
        <div className="section3-main">
            <h2>Say Goodbye to crappy data</h2>
            <div className="section3">
                {Section3Data.map((index)=>{
                    return(<Section3Lay
                        key={index.id}
                        img= {index.img}
                        title={index.title}
                        des={index.des}
                        />)
                })}
            </div>
        </div>
    )
}
export default Section3