const Section4Data = [{
    id: 1,
    img: 'https://cdn.cmsfly.com/64105a606115ac047190cee0/pexels-thisisengineering-3861969-1-9pYGkp.png',
    title: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, delectus.',
    des:'Read More'
},
{
    id: 2,
    img: 'https://cdn.cmsfly.com/64105a606115ac047190cee0/pexels-thisisengineering-3912981-1-18sIGS.png',
    title: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, delectus.',
    des:'Read More'
},
{
    id: 3,
    img: 'https://cdn.cmsfly.com/64105a606115ac047190cee0/pexels-mali-maeder-756439-1-yY2f49.png',
    title: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, delectus.',
    des:'Read More'
},


]
export default Section4Data