import React from "react";
import './Testimonial.css'
function Testimonial(props) {
    return (
            <div className="testimonial_box">
                <div className="testimonial_text">
                    <p>{props.text}</p>
                    <h4>{props.name}</h4>
                </div>
                <div className="testimonial_image">
                    <img src={props.img} alt="WPenguino" />
                </div>
            </div>
          


    )
}
export default Testimonial