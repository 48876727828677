import React from "react";
import './Section1.css'
import Img from '../../../../Assets/Images/6.svg'
import Img1 from '../../../../Assets/Images/group-26-7rPhzj.png'
import Img2 from '../../../../Assets/Images/7.svg'
import Img3 from '../../../../Assets/Images/8.svg'
import Img4 from '../../../../Assets/Images/sfd-opxTfF.png'
import {motion} from 'framer-motion'
const Section1 = ()=>{
    const Section1box = {
        hidden: {
            x: 120,
            opacity: 0,
        },
        visible:{
            x:0,
            opacity:1,
            transition: {
                duration: 0.8,
                type:'spring',
                stiffness: 50,
                mass: 0.4,
                damping: 8,
                staggerChildren: 20,
                when: 'beforeChildren',
                delay: 0.5
            }
        }
    }
    return(
        <div variants={Section1box} initial='hidden' whileInView='visible' className="section1-main">
            <motion.div variants={Section1box} initial='hidden' whileInView='visible' className="section1-box">
                <div className="section1-firstcon">
                <img src={Img} alt="wpenguino" />
<h3>Fraud prevention</h3>
<p>Nor the government which has way, the met temple in out of after she and take I it the an of people contract.</p>
                </div>
<div className="section1-secondcon">

<img src={Img1} alt="wpenguino" />
</div>
            </motion.div>
            <motion.div variants={Section1box} initial='hidden' whileInView='visible' className="section1-box">
                <div className="section1-firstcon">
                <img src={Img2} alt="wpenguino" />
<h3>Misleading CRM data</h3>
<p>Nor the government which has way, the met temple in out of after she and take I it the an of people contract.</p>
                </div>
<div className="section1-secondcon">

<img src={Img4} alt="wpenguino" />
</div>
            </motion.div>
            <motion.div  variants={Section1box} initial='hidden' whileInView='visible'  className="section1-box">
                <div className="section1-firstcon">
                <img src={Img3} alt="wpenguino" />
<h3>Data compliance</h3>
<p>Nor the government which has way, the met temple in out of after she and take I it the an of people contract.</p>
                </div>
<div className="section1-secondcon">

<img src={Img1} alt="wpenguino" />
</div>
            </motion.div>
          
        </div>
    )
}
export default Section1