const Section3Data = [{
    id: 1,
    img: 'https://cdn.cmsfly.com/64105a606115ac047190cee0/fy-WluHUP.svg',
    title: 'Lorem ipsum dolor sit amet.',
    des: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam ad aspernatur vel nihil enim doloremque! Illo, iure animi. A vero id quis.'
},
{
    id: 2,
    img: 'https://cdn.cmsfly.com/64105a606115ac047190cee0/fy-WluHUP.svg',
    title: 'Lorem ipsum dolor sit amet.',
    des: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam ad aspernatur vel nihil enim doloremque! Illo, iure animi. A vero id quis.'
},
{
    id: 3,
    img: 'https://cdn.cmsfly.com/64105a606115ac047190cee0/fy-WluHUP.svg',
    title: 'Lorem ipsum dolor sit amet.',
    des: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam ad aspernatur vel nihil enim doloremque! Illo, iure animi. A vero id quis.'
},
{
    id: 4,
    img: 'https://cdn.cmsfly.com/64105a606115ac047190cee0/fy-WluHUP.svg',
    title: 'Lorem ipsum dolor sit amet.',
    des: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam ad aspernatur vel nihil enim doloremque! Illo, iure animi. A vero id quis.'
},
{
    id: 5,
    img: 'https://cdn.cmsfly.com/64105a606115ac047190cee0/fy-WluHUP.svg',
    title: 'Lorem ipsum dolor sit amet.',
    des: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam ad aspernatur vel nihil enim doloremque! Illo, iure animi. A vero id quis.'
},
{
    id: 6,
    img: 'https://cdn.cmsfly.com/64105a606115ac047190cee0/fy-WluHUP.svg',
    title: 'Lorem ipsum dolor sit amet.',
    des: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam ad aspernatur vel nihil enim doloremque! Illo, iure animi. A vero id quis.'
}
]
export default Section3Data