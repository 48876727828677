import React from "react";
import './Section4-lay.css'
import {motion} from 'framer-motion'
const Section4lay = (props)=>{
    const Section4box = {
        hidden:{
            y:0,
        },
        visible:{
            y: -20,
            transition:{
                
                mass: 0.5,
                damping: 1
            },
            
        }
    }

    return(
        <motion.div variants={Section4box} initial='hidden' whileHover='visible' className="section4-box">
            <img src={props.img} alt="wpenguino" />
            <div className="section4-text">
            <h3>{props.title}</h3>
          <h4>{props.des}</h4>
          </div>
        </motion.div>
    )
}
export default Section4lay