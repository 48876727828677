import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import "./App.css";
import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import {AnimatePresence} from 'framer-motion'
import PricingPage from "./Pages/Pricingpage/PricingPage";
import About from "./Pages/About/About";
import Contact from "./Pages/ContactPage/Contact";
import FeaturePage from "./Pages/FeaturePage/Featurepage";
import PageNotFound from "./Pages/404Page/404Page";
import { useDispatch, useSelector } from "react-redux";
import Home2 from "./Pages/Home2/home2";
function App() {
  const dispatch = useDispatch();
  const test = useSelector((state) => state.verification);
  console.log(test);
  const change = () => {
    dispatch({ type: "afterJwtVerification" });
  };

  const postData = async () => {
    try {
      const response = await fetch(
        "https://dashboardenvato.hitoritech.com/api/v1/visitor",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ domain: window.location.origin }), // Replace with your request body
        }
      );

      const data = await response.json();
      console.log(data);
      if (data.statusCode === 200) {
        console.log("verified");
      } else {
        change();
      }
    } catch (error) {
      console.error(error);
    }
  };
  postData();
  const PrivateRoute = () => {
    let auth = { token: test.Token };

    return auth.token ? <Outlet /> : <Navigate to="/verification" />;
  };

const location = useLocation()

  return (
    <div className="App">
     <AnimatePresence>
        <Routes location={location} key={location.key}>
          <Route element={<PrivateRoute />}>
            <Route key="frontpage" element={<Home />} path="/" exact />
            <Route key="home" element={<Home />} path="/home" exact />
            <Route key="home2" element={<Home2 />} path="/home2" exact />
            <Route
              key="pricing"
              element={<PricingPage />}
              path="/pricing"
              exact
            />
            <Route
              key="features"
              element={<FeaturePage />}
              path="/features"
              exact
            />
            <Route key="about" element={<About />} path="/about" exact />
            <Route key="contact" element={<Contact />} path="/contact" exact />
            <Route key="pagenotfound" element={<PageNotFound />} path="/*" />
          </Route>
          <Route key="lorem" element={<Login />} path="/verification" />
        </Routes>
        </AnimatePresence>
    </div>
  );
}

export default App;
