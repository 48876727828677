import React from "react";
import { Link } from "react-router-dom";
import { ArrowRight } from 'feather-icons-react/build/IconComponents'
import './Feature.css'
import {motion} from 'framer-motion'
function Feature(props) {
    const Animation = {
        hidden:{
            opacity: 0,
            y:75,
        },
        visible:{
            opacity:1,
            y:0,
            transition:{
                duration: 0.7,
                staggerChildren: 0.4,
                when: 'beforeChildren'
            }
        }
    
    }
    return (
      
            <motion.div variants={Animation} initial='hidden' animate='visible' className="home_third_box">
                <img src={props.img} alt="WPenguino" />
                <h3>{props.title}</h3>
                <p>{props.description}</p>
                <Link to="/features"><ArrowRight /></Link>
            </motion.div>


    )
}
export default Feature