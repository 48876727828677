import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  Token: true,
};

export const customReducer = createReducer(initialState, {
  afterJwtVerification: (state) => {
    state.Token = false;
  },
});
