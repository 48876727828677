import React from "react";
import './home2.css'
import Header2 from "../../Components/Header2/Header2";
import Footer from '../../Components/Footer/Footer'
import { Link } from "react-router-dom";
import {ArrowRight, Check} from 'feather-icons-react/build/IconComponents'
import Img from '../../Assets/Images/group-25-7kjALN.svg'
import Img1 from '../../Assets/Images/1.svg'
import Img2 from '../../Assets/Images/2.svg'
import Img3 from '../../Assets/Images/3.svg'
import Img4 from '../../Assets/Images/4.svg'
import Img5 from '../../Assets/Images/5.svg'
import Section1 from "./Home2Comp/Section1/Section1";
import Section2 from "./Home2Comp/Section2/Section2";
import Section3 from "./Home2Comp/Section3/Section3";
import Section4 from "./Home2Comp/Section4/Section4";
import Section5 from "./Home2Comp/Section5/Section5";
import {motion} from 'framer-motion'
const Home2 = ()=>{

  const PageTransitions = {
    hidden:{
        opacity:0,
    },
    visible:{
        opacity:1,
        transition:{
          mass: 0.2,
          damping: 1,
          when:'beforeChildren'
        }
    },
}

  const Home2 = {
    hidden:{
      y:75,
      opacity:0,
    },
    visible:{
      y:0,
      opacity:1,
      transition:{
        mass: 0.5,
        damping: 1,
      
      }
    }
  }
  const Images={
    hidden: {
      opacity:0,
      scale:0,

    },
    visible:{
      opacity:1,
      scale:1,
      transition:{
        duration: 1,
        mass: 0.5,
        damping:1
      }
    }
  }

    return(<>
            <Header2 />
        <motion.div variants={PageTransitions} initial='hidden' whileInView='visible' className="home2-main">
            <div className="home2-container">
          <motion.h3 variants={Home2} initial='hidden' whileInView='visible'>Build for data Wizard<ArrowRight /></motion.h3>
          <motion.h1 variants={Home2} initial='hidden' whileInView='visible'>Build data pipelines in the easiest way</motion.h1>
          <motion.p variants={Home2} initial='hidden' whileInView='visible'>Because let are inn, this the to last of the himself lamps, that have former half with something the lay writing does associates</motion.p>
          <motion.div variants={Home2} initial='hidden' whileInView='visible' className="home2-btncontainer">
           <Link to='/features'><button className="btn1">Get Started for Free</button></Link> 
           <Link to='/pricing'><button className="btn2">Book A Demo</button></Link> 
          </motion.div>
          <motion.p variants={Home2} initial='hidden' whileInView='visible'><Check /> No Credit or Debit Card Required</motion.p>
          <img src={Img} alt="wpenguino" />
          <div className="home2-imgcont">
            <motion.img variants={Images} initial='hidden' whileInView='visible' src={Img1} alt="wpenguino" />
            <motion.img variants={Images} initial='hidden' whileInView='visible' src={Img2} alt="wpenguino" />
            <motion.img variants={Images} initial='hidden' whileInView='visible' src={Img3} alt="wpenguino" />
            <motion.img variants={Images} initial='hidden' whileInView='visible' src={Img4} alt="wpenguino" />
            <motion.img variants={Images} initial='hidden' whileInView='visible' src={Img5} alt="wpenguino" />
          </div>
          </div>
          <div className="home2-section1">
            <motion.h2 variants={Home2} initial='hidden' whileInView='visible'>Why You Choose<br/>
            <span>Wpenguino</span>
            </motion.h2>
<Section1 />
          </div>
<Section2 />
<Section3 />
<Section4 />
<Section5 />
        </motion.div>
          <Footer />
        </>
    )
}
export default Home2