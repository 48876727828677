import React from "react";
import './Section5.css'
import Img from '../../../../Assets/Images/knkn-949DzO.svg'
import Img1 from '../../../../Assets/Images/knkn-yFJIb9.svg'
import Img2 from '../../../../Assets/Images/kjnk-NWxcgz.svg'
import Img3 from '../../../../Assets/Images/knkp-Fx1Oz-.svg'
import {Check, X} from "feather-icons-react/build/IconComponents";
import { Link } from "react-router-dom";
import {motion} from 'framer-motion'
const Section5box = {
    hidden:{
        y:0,
    },
    visible:{
        y: -20,
        transition:{
            
            mass: 0.5,
            damping: 1
        }
    }
}
const Images={
    hidden: {
      opacity:0,
      scale:0,

    },
    visible:{
      opacity:1,
      scale:1,
      transition:{
        duration: 1,
        mass: 0.5,
        damping:1
      }
    }
  }
const Section5= ()=>{
    return(
        <div className="section5-main">
            <h2>Integrates with your favourite database</h2>
            <div className="section5-img">
<motion.img variants={Images} initial='hidden' whileInView='visible' src={Img} alt="wpenguino" />
<motion.img variants={Images} initial='hidden' whileInView='visible' src={Img1} alt="wpenguino" />
<motion.img variants={Images} initial='hidden' whileInView='visible' src={Img2} alt="wpenguino" />
<motion.img variants={Images} initial='hidden' whileInView='visible' src={Img3} alt="wpenguino" />
            </div>
                <h2>Our payment plan is easy to understand</h2>
            <div className="section5-container">
                <motion.div  variants={Section5box} initial='hidden' whileHover='visible' className="section5-box">
                    <h2>Free Plan</h2>
                    <p><Check/>10k Submissions per month</p>
                    <p><Check/>2 API Credentials</p>
                    <p><Check/>1 user (that's you!)</p>
                    <p className="not-include"><X/>Firewall Security</p>
                    <p className="not-include"><X/>Multiple Devices</p>
                    <p className="not-include"><X/>Dedicated Support</p>
                    <Link to='/pricing'><button>Get Started For Free</button></Link>
                </motion.div>
                <motion.div variants={Section5box} initial='hidden' whileHover='visible' className="section5-box">
                    <h2>Basic Plan</h2>
                    <p><Check/>10k Submissions per month</p>
                    <p><Check/>2 API Credentials</p>
                    <p><Check/>5 Team Members</p>
                    <p><Check/>Firewall Security</p>
                    <p><Check/>Multiple Devices</p>
                    <p className="not-include"><X/>Dedicated Support</p>
                    <Link to='/pricing'><button>Get Started</button></Link>
                </motion.div>
                <motion.div variants={Section5box} initial='hidden' whileHover='visible' className="section5-box">
                    <h2>Premium Plan</h2>
                    <p><Check/>10k Submissions per month</p>
                    <p><Check/>2 API Credentials</p>
                    <p><Check/>10 Team Members</p>
                    <p><Check/>Firewall Security</p>
                    <p><Check/>Multiple Devices</p>
                    <p><Check/>Dedicated Support</p>
        <Link to='/pricing'><button>Get Started</button></Link>
                </motion.div>
               
            </div>
        </div>
    )

}
export default Section5