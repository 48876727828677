import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import {motion} from 'framer-motion'
function Login() {
  const check = async () => {
    try {
      const response = await fetch(
        "https://dashboardenvato.hitoritech.com/api/v1/visitor",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ domain: window.location.origin }), // Replace with your request body
        }
      );

      const data = await response.json();
      console.log(data);
      if (data.statusCode === 200) {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };
  check();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [licenseKey, setLicenseKey] = useState("");
  // function hrefFunction() {
  //   window.location.href = "/";
  // }
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a request body object
    const requestBody = {
      user: username,
      license: licenseKey,
      domain: window.location.origin,
    };

    try {
      const response = await fetch(
        "https://dashboardenvato.hitoritech.com/api/v1/testing",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      // Handle the response here
      if (response) {
        console.log(response.status);
        window.location.reload(true);
        navigate("/");
      } else {
        console.log("Login failed");
      }
    } catch (error) {
      console.log("Error occurred while making the request:", error);
    }
  };

  const PageTransitions = {
    hidden:{
        opacity:0,
    },
    visible:{
        opacity:1,
        transition:{
            duration: 1,
        }
    }
}
  
  return (
    <>
      <Header />
      <motion.div variants={PageTransitions} initial='hidden' whileInView='visible' className="login_main">
        <div className="login_container">
          <h1>Login</h1>
          <p>Enter Details to Login</p>
          <form onSubmit={handleSubmit}>
            <input
              id="user"
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              id="license"
              maxLength={30}
              type="text"
              placeholder="License Key"
              value={licenseKey}
              onChange={(e) => setLicenseKey(e.target.value)}
            />
            <button type="submit">Login</button>
          </form>
        </div>
      </motion.div>
    </>
  );
}

export default Login;
