import React from "react";
import Header from "../../Components/Header/Header";
import './Home.css'
import Feature from "./Home_Components/Features/Feature";
import Featuredata from "./Home_Components/Features/Featuredata";
import Pricing from "./Home_Components/Pricing/Pricing";
import { LogIn, UserPlus, MessageSquare } from 'feather-icons-react/build/IconComponents'
import Testimonial from "./Home_Components/Testimonials/Testimonial";
import Testimonialdata from "./Home_Components/Testimonials/Testimonialdata";
import Footer from "../../Components/Footer/Footer";
import image2 from '../../Assets/Images/image2.png'
import image3 from '../../Assets/Images/image3.png'
import image37 from '../../Assets/Images/image37.png'
import image6 from '../../Assets/Images/image6.png'
import image8 from '../../Assets/Images/image8.png'
import image7 from '../../Assets/Images/image7.png'
import image9 from '../../Assets/Images/image9.png'
import image10 from '../../Assets/Images/image10.png'
import image11 from '../../Assets/Images/image11.png'
import image15 from '../../Assets/Images/image15.png'
import image16 from '../../Assets/Images/image16.png'
import image17 from '../../Assets/Images/image17.png'
import image18 from '../../Assets/Images/image18.png'
import image19 from '../../Assets/Images/image19.png'
import image22 from '../../Assets/Images/image22.png'
import image23 from '../../Assets/Images/image23.png'
import { Link } from "react-router-dom";
import {motion} from 'framer-motion'
function Home() {


const PageTransitions = {
    hidden:{
        opacity:0,
    },
    visible:{
        opacity:1,
        transition:{
            duration: 1,
        }
    }
}



const Animation = {
    hidden:{
        opacity: 0,
        y:75,
    },
    visible:{
        opacity:1,
        y:0,
        transition:{
            duration: 0.5,
            staggerChildren: 0.4,
            when: 'beforeChildren'
        }
    }

}

    return (
        <motion.div variants={PageTransitions} initial='hidden' whileInView='visible' className="home_main">
            <div className="home_Container">
                <Header />
                <motion.div variants={Animation} initial="hidden" whileInView='visible' className="home_firstsec">
                    <h1>Boost your marketing with <span>WPenguino</span></h1>
                    <div className="home_btncontainer">
                    <Link to ="/pricing"><button className="home_first_btn"><LogIn />Get Start</button></Link>
                    <Link to ="/pricing"> <button className="home_second_btn"><UserPlus />Try for Free</button></Link>
                    </div>
                    <p className="p_span" >Have a query?<span > Contact us</span></p>
                </motion.div>
            </div>
            <div variants={Animation} initial="hidden" whileInView='visible' className="home_imgContainer">
                <img src={image2} alt="Wpenguino" />
                <h3>Over 32K+ software businesses growing with WPenguino</h3>
            </div>
            <motion.div  variants={Animation} initial="hidden" whileInView='visible' className="home_secondsec">
                <motion.div variants={Animation} initial="hidden" whileInView='visible' className="home_Secondsec_left">
                    <img src={image37}alt="WPenguino" />
                    <h2>More than 13,000 teams use our platform</h2>
                </motion.div>
                <motion.div variants={Animation} initial="hidden" whileInView='visible' className="home_Secondsec_right">
                    <img src={image3} alt="WPenguino" />
                </motion.div>
            </motion.div>
            <motion.div   variants={Animation} initial="hidden" whileInView='visible' className="home_thirdsec">
                <h2>Explore Our Amazing Features 🔥</h2>
                <div className="home_third_boxcontainer">
                    {Featuredata.map(index => {
                        return <Feature
                            key = {index.id}
                            img={index.img}
                            title={index.title}
                            description={index.description}
                        />

                    })}
                </div>
                <Pricing />
            </motion.div>
            <motion.div variants={Animation} initial="hidden" whileInView='visible' className="home_forthsec">
                <div className="home_forthsec_box1">
                    <img className="forthsec_boximg" src={image6} alt="WPenguino" />
                    <img className="forthsec_boximg1" src={image8} alt="WPenguino" />
                    <img className="forthsec_boximg2" src={image7} alt="WPenguino" />
                </div>
                <div className="home_forthsec_box2">
                    <h2>Start Building Chatbot using WPenguino</h2>
                    <p>We provide a bird`s eye perspective of your entire bot, which aids in the development of a highly engaging bot. You can create, test, and build chatbots graphically using a single no-code online interface.</p>
                    <Link to="/lorem"> <button>LOGIN</button></Link>
                </div>
            </motion.div>
            
            <motion.div variants={Animation} initial="hidden" whileInView='visible' className="home_fifthsec">
                <div className="home_fifthleft">
                    <h2>Create your free account</h2>
                    <h3>It takes only 3 minutes to get started</h3>
                    <p>Plug your messaging channels, install your widget and you’re ready to go</p>
                    <div className="home_inputcontainer">
                        <input type="text" placeholder="example@gmail.com" />
                        <Link to= "/pricing" ><button>SIGN UP<LogIn /></button></Link>
                    </div>
                    <h4># Also available mobile apps</h4>
                    <div className="home_fifthimgcontainer">
                      <Link to="/pricing"><img src={image23} alt="WPenguino" /></Link>  
                  <Link to= "/pricing" ><img src={image22} alt="WPenguino" /></Link>   
                    </div>
                </div>
                <div className="home_fifthright">
                    <img className="home_fifthimg1" src={image10} alt="WPenguino" />
                    <img className="home_fifthimg2" src={image9} alt="WPenguino" />
                    <img className="home_fifthimg3" src={image11} alt="WPenguino" />
                </div>
            </motion.div>
            <motion.div variants={Animation} initial="hidden" whileInView='visible' className="home_testimonial">
                <h2>Testimonials</h2>
                <div className="home_Testimonial_main">
                    {Testimonialdata.map(index => {
                        return <Testimonial
                            key={index.id}
                            text={index.text}
                            name={index.name}
                            img={index.img}
                        />
                    })}
                </div>
            </motion.div>

            <motion.div variants={Animation} initial="hidden" whileInView='visible' className="home_lastsecond">
                <img className="shape1" src={image15} alt="WPenguino" />
                <img className="shape2" src={image16} alt="WPenguino" />
                <img className="shape3" src={image17} alt="WPenguino" />
                <img className="shape4" src={image18} alt="WPenguino" />
                <img className="shape5" src={image19} alt="WPenguino" />
                <h2>Discover a better way to manage spendings</h2>
                <div className="home_lastsecondbtn">
                <Link to="/pricing"> <button>GET STARTED NOW</button></Link>
                </div>
            </motion.div>

<motion.div variants={Animation} initial="hidden" whileInView='visible' className="home_lastsection">
<div className="home_lastsec_box">
<MessageSquare fill= "black" size=" 55px" />
<h3>Do you have more questions?</h3>
<p>End-to-end payments and financial management in a single solution. Meet the right platform to help realize.</p>

<Link to = "/contact"> <button>Shoot a Direct Mail</button></Link>

</div>
</motion.div>

<Footer />

        </motion.div>
    )
}
export default Home