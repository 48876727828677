import React,{useState} from "react";
import Header2 from "../../Components/Header2/Header2";
import {ArrowRight,Check,PlayCircle,MessageSquare} from 'feather-icons-react/build/IconComponents'
import './About.css'
import Pricing from "../Home/Home_Components/Pricing/Pricing";
import Team from "./About_Components/Team";
import Footer from "../../Components/Footer/Footer";
import Image1 from '../../Assets/Images/image1.png'
import Image28 from '../../Assets/Images/image28.png'
import Image4 from '../../Assets/Images/img4.webp'
import Image29 from '../../Assets/Images/image29.png'
import Image30 from '../../Assets/Images/image30.png'
import Image31 from '../../Assets/Images/image31.png'
import Image32 from '../../Assets/Images/image32.png'
import Img33 from '../../Assets/Images/download.png'
import { Link } from "react-router-dom";
import {motion} from 'framer-motion'
function About(){
    const [text, setText] = useState(0)
    function show(index){
        setText(index === text ? null : index)
    }

    const PageTransitions = {
        hidden:{
            opacity:0,
        },
        visible:{
            opacity:1,
            transition:{
                duration: 1,
            }
        }
    }
    
    
    
    const Animation = {
        hidden:{
            opacity: 0,
            y:75,
        },
        visible:{
            opacity:1,
            y:0,
            transition:{
                duration: 0.5,
                staggerChildren: 0.4,
                when: 'beforeChildren'
            }
        }
    
    }
    
    return (
        <motion.div variants={PageTransitions} initial='hidden' whileInView='visible'>
    <Header2 />
    <motion.div variants={Animation} initial = 'hidden' whileInView='visible' className="aboutpage_firstsec">
        <p>ABOUT COMPANY</p>
        <h2>Over 12 years of experience in the IT Industry & Tech service</h2>
        <div className="aboutpage_firstsecboxcont">
        <div className="aboutpage_firstleftbox">
        <img className="aboutimg1" src={Image1} alt="WPenguino" />
        <img className = "aboutimg2"src={Image28} alt="Wpenguino" />
        </div>
        <div className="aboutpage_firstrightbox">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem ullam atque pariatur ut ratione, necessitatibus dolore, nemo fugiat tempora sit vel molestiae cum, minus illum laborum magnam ipsum quae inventore nulla! Quibusdam minima ullam vitae eveniet magni tenetur odio, assumenda at, exercitationem aperiam distinctio iure quasi dolorem aliquam! Assumenda pariatur suscipit, architecto explicabo aliquid qui consequuntur repellat aspernatur esse sequi?
</p>
<div className="aboutfirst_rightbox_feature">
    <p><Check />Fast support</p>
    <p><Check />Stable api response</p>
    <p><Check />Reasonable price</p>
    <p><Check />User-friendly UX</p>
    <p><Check />Regular features update</p>
</div>
<Link to="#"><PlayCircle size="50px"/></Link>
        </div>
        </div>
    </motion.div>

    <motion.div variants={Animation} initial='hidden' whileInView='visible' className="about_work">
    <div className="aboutwork_leftcon">
<strong>Lorem, ipsum dolor.</strong>
<h2>Lorem ipsum dolor sit amet.</h2>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At facilis labore provident veritatis dolor. Quod nam ab laborum! Voluptate, iure consequuntur. Ullam illum maiores natus, quasi at eum!</p>
<p>Lorem ipsum dolor sit.</p>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis voluptatibus modi ad reiciendis optio numquam odit ex deleniti, veniam quia a nostrum dolorum eligendi non itaque harum! Atque!</p>
<div className="aboutwork_leftconbutton">
<Link to="/contact"><button>CONTACT <ArrowRight/></button></Link>
</div>
    </div>
    <div className="aboutwork_rightcon">
<img src={Image4} alt="WPenguino" />
    </div>
</motion.div>

<motion.div variants={Animation} initial='hidden' whileInView='visible' className="aboutpage_secondsec">
    <div className="aboutpage_secondsecfirst">
        <h2>Our Features</h2>
        <Link to="/features"> <button>SEE ALL FEATURES</button></Link>
    </div>
    <div className="about_features">
        <div className="about_feature1">
        <img src={Img33} alt="WPenguino" />
        <h2>Lorem, ipsum.</h2>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias quisquam neque vero nostrum magni blanditiis sint earum officiis!</p>
        <Link to="/features"><ArrowRight /></Link>
        </div>
        <div className="about_feature2">
       <img src={Img33}  alt="WPenguino" />
       <h2>Lorem, ipsum.</h2>
       <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias quisquam neque vero nostrum magni blanditiis sint earum officiis!</p>
       <Link to="/features"><ArrowRight /></Link>
        </div>
    </div>
    <div className="about_clientssec">
        <div className="about_clients">
            <div className="about_clientsecimg">
            <img src={Image29} alt="WPenguino" />
          </div>
          <div className="about_clientsectext">
            <h2>12+</h2>
            <p>Years of Experience</p>
            </div>
        </div>
        <div className="about_clients">
            <div className="about_clientsecimg">
            <img src={Image30} alt="WPenguino" />
            </div>
            <div className="about_clientsectext">
            <h2>900+</h2>
            <p>Active Customers</p>
            </div>
        </div>
        <div className="about_clients">
            <div className="about_clientsecimg">
            <img src={Image31} alt="WPenguino" />
           </div>
           <div className="about_clientsectext">
            <h2>200+</h2>
            <p>Positive Reviews</p>
            </div>
        </div>
        <div className="about_clients">
            <div className="about_clientsecimg">
            <img src={Image32} alt="WPenguino" />
            </div>
            <div className="about_clientsectext">
            <h2>800+</h2>
            <p>Satisfied Customers</p>
            </div>
        </div>
    </div>
</motion.div>
<Pricing />
<Team />
<motion.div variants={Animation} initial='hidden' whileInView='visible' className="about_lastsection">
    <div className="about_lastsectionleft">
 <div onClick={()=>show(1)} className="about_textcontainer">
    <p className="about_activetext">
    How it will take impact for Food & Restaurants business.
     <span>{text === 1 ? "-" : "+"}</span></p>
     {text === 1 ? <p className="about_text">Revolutionize your restaurant operations with WhatsApp-based ordering, meal and add-on recommendations, payments and real-time delivery updates</p> :null}
    
 </div>
 <div  onClick = {()=>show(2)}className="about_textcontainer">
    <p className="about_activetext">
    Financial Services
     <span>{text === 2 ? "-" : "+"}</span></p>
     {text === 2 ? <p className="about_text">Boost digital finance adoption with instant balance inquiries, portfolio recommendations, hassle-free claims settlement, cross-sell and more</p>:null}
     
 </div>
 <div  onClick = {()=>show(3)}className="about_textcontainer">
    <p className="about_activetext">
    Do you offer a 30 day money-back guarantee?
     <span> {text ===3 ? "-" : "+"} </span></p>
     {text === 3 ? <p className="about_text">Yes, If you are unhappy with our service, we offer 30 days money-back guarantee on any plan.</p> : null}
    
 </div>
 <div  onClick = {()=>show(4)}className="about_textcontainer">
    <p className="about_activetext">
    Do I need a credit card to sign up?
     <span> {text ===4 ? "-": "+"} </span></p>
     {text === 4 ?  <p className="about_text">No, you only need a email to sign up.</p> : null }
   
 </div>
 <div onClick = {()=>show(5)} className="about_textcontainer">
    <p className="about_activetext">
    Why does your business need a Chatbot?
     <span> {text === 5 ? "-" : "+"} </span></p>
     {text === 5 ? <p className="about_text">A chatbot is always available to serve your customer 24/7. A chatbot can save you a lot of time and money by automating repetitive tasks like qualifying leads or answering questions of your customers. By offering instatily response to your customer, your business will build trust and boost conversions and sales.</p> :null }
    
 </div>
    </div>
    <div className="about_lastsectionright">
    <div className="about_lastsec_box">
<MessageSquare fill= "black" size=" 55px" />
<h3>Do you have more questions?</h3>
<p>End-to-end payments and financial management in a single solution. Meet the right platform to help realize.</p>

    <button><Link to="/contact">Shoot a Direct Mail</Link></button>

</div>
    </div>
</motion.div>
<Footer />
    </motion.div>
    )
}
export default About