import React from "react";
import './Contact.css'
import Header2 from "../../Components/Header2/Header2";
import { MapPin, PhoneCall, Mail } from 'feather-icons-react/build/IconComponents'
import Footer from '../../Components/Footer/Footer'
import {motion} from 'framer-motion'
function Contact() {
    const PageTransitions = {
        hidden:{
            opacity:0,
        },
        visible:{
            opacity:1,
            transition:{
                duration: 1,
            }
        }
    }
    
    
    
    const Animation = {
        hidden:{
            opacity: 0,
            y:75,
        },
        visible:{
            opacity:1,
            y:0,
            transition:{
                duration: 0.5,
                staggerChildren: 0.4,
                when: 'beforeChildren'
            }
        }
    
    }
    return (
        <motion.div variants={PageTransitions} initial='hidden' whileInView='visible'>
                <Header2 />
                
                        <motion.div variants={Animation} initial='hidden' whileInView='visible' className="contact_details">
<h2>Reach Out To Us For A Consultation Today</h2>
<p>Owning a business means managing your IT isn’t your primary focus. A member of our experienced account management team would be happy to discuss your IT needs with you, whether you are looking for ways to streamline, expand, or protect your network.</p>
</motion.div>
            <motion.div variants={Animation} initial='hidden' whileInView='visible' className="contact_boxContainer">
                <div className="contact_box">
                    <MapPin />
                    <h3>Visit Us Daily</h3>
                    <p>Bowery St, New York,<br />NY 10013,USA</p>
                </div>
                <div className="contact_box">
                    <PhoneCall />
                    <h3>Contact Us</h3>
                    <p>8801234567890<br />8801234567891</p>
                </div>
                <div className="contact_box">
                    <Mail />
                    <h3>Email Us</h3>
                    <p>support@email.com<br />contact@email.com</p>
                </div>
            </motion.div>
            <motion.div variants={Animation} initial='hidden' whileInView='visible' className="contact_secondsec">
                <h1>Send us a Message:</h1>
            </motion.div>
            <motion.div variants={Animation} initial='hidden' whileInView='visible' className="contact_input">
                <input type="text" className="name" placeholder="Enter Your Name" />
                <input type="email" className="mail" placeholder="Enter Your Mail" />
                <input type="number" className="number" placeholder="Enter Your Number" />
                <input type="text" className="subject" placeholder="Subject" />
                <textarea className="textarea" placeholder="Type your message"></textarea>
                <button>Send Message</button>
            </motion.div>
            <Footer />
        </motion.div>
    )
}
export default Contact