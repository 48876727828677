import React from "react";
import './Pricing.css'
import { CheckCircle, Star} from 'feather-icons-react/build/IconComponents'
import { Link } from "react-router-dom";
import {motion} from 'framer-motion'
function Pricing() {
    const Animation = {
        hidden:{
            opacity: 0,
            y:75,
        },
        visible:{
            opacity:1,
            y:0,
            transition:{
                duration: 0.7,
                staggerChildren: 0.4,
                when: 'beforeChildren'
            }
        }
    
    }
    return (
        <motion.div variants={Animation} initial='hidden' animate='visible' className="home_pricingsec">
            <h2>Pricing to suite all size of<br />business</h2>
            <p>*We help companies of all sizes</p>

            <div className="pricing_container">
                <div className="pricing_box1">
                    <p><CheckCircle />BASIC</p>
                    <h2>$20.00 /month</h2>
                    <div className="pricelist">
                        <ul>
                            <li>Messages limit (unlimited)</li>
                            <li>Contact limit (100)</li>
                            <li>Device limit (100)</li>
                            <li>Template limit (100)</li>
                            <li>Apps limit (10)</li>
                            <li>Schedule message</li>
                            <li>Access chat list</li>
                        </ul>
                    </div>
                    <div className="pricing_btncontainer1">
                    <Link to="#"> <button> SIGN UP NOW </button></Link>
                    </div>
                </div>
                <div className="pricing_box2">
                    <p><CheckCircle />ENTERPRISE</p>
                    <h2>$50.00 /month</h2>
                    <div className="pricelist">
                        <ul>
                            <li>Messages limit (unlimited)</li>
                            <li>Contact limit (unlimited)</li>
                            <li>Device limit (unlimited)</li>
                            <li>Template limit (unlimited)</li>
                            <li>Apps limit (unlimited)</li>
                            <li>Chatbot</li>
                            <li>Bulk message</li>
                            <li>Schedule message</li>
                            <li>Access chat list</li>
                            <li>Access group list</li>

                        </ul>
                    </div>
                    <div className="pricing_btncontainer2">
                    <Link to="#"> <button> SIGN UP NOW </button></Link>
                    </div>
                </div>
                <div className="pricing_box3">
                    <p><Star />STARTER</p>
                    <h2>$10.00 /month</h2>
                    <div className="pricelist">
                        <ul>
                            <li>Messages limit (1000)</li>
                            <li>Contact limit (100)</li>
                            <li>Device limit (3)</li>
                            <li>Template limit (20)</li>
                            <li>Apps limit (10)</li>
                            <li>Chatbot</li>
                        </ul>
                    </div>
                    <div className="pricing_btncontainer3">
                    <Link to="#"> <button>SIGN UP NOW </button></Link>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}
export default Pricing