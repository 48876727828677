import React from "react";
import './Section3-lay.css'
import {motion} from 'framer-motion'
const Section3Lay = (props)=>{
    const BoxAnimation = {
        hidden: {
            x: 120,
            opacity: 0,
        },
        visible:{
            x:0,
            opacity:1,
            transition: {
                duration: 0.8,
                type:'spring',
                stiffness: 50,
                mass: 0.4,
                damping: 8,
                staggerChildren: 20,
                when: 'beforeChildren',
                delay: 0.5
            }
        }
    }
    return(
        <motion.div variants={BoxAnimation} initial='hidden' whileInView='visible' className="section3-box">
            <img src={props.img} alt="wpenguino" />
            <h3>{props.title}</h3>
            <p>{props.des}</p>
        </motion.div>
    )
}
export default Section3Lay