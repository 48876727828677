import React from "react";
import './Section4.css'
import Section4Data from "./Section4-data";
import Section4lay from "./Section4-lay";
import { Link } from "react-router-dom";
const Section4 = ()=>{
return(
    <div className="section4-main">
        <h2>Data infrastructure entity resolution software case study</h2>
        <div className="section4">
            {Section4Data.map((index)=>{
                return(<Section4lay
                    key={index.id}
                    img={index.img}
                    title={index.title}
                    des={index.des}
                    
                    />)
            })}
        </div>
        <Link to='/features'><button>Load More</button></Link>
    </div>
)
}
export default Section4