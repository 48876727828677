import React,{useState} from "react";
import {Link} from 'react-router-dom'
import {User, Menu, X, Phone, Mail, MapPin, Facebook, Instagram, Twitter, Linkedin} from 'feather-icons-react/build/IconComponents'
import image20 from '../../Assets/Images/image20.png'
import image21 from '../../Assets/Images/image21.png'
import './Header2.css'
import {motion} from 'framer-motion'
// For All Other Pages except Home
function Header2(){
const [menu, setMenu] = useState(false)
function show(){
    setMenu(prev => !prev)
}
const header = {
    hidden:{
        y: '-100%',
    },
    visible:{
        y:0
    }
}

return (
    <div className="header2_main">
        {/* logo of the company */}
        <div className="header2_logo"><Link to = '/'>
            <motion.img variants={header} initial='hidden' animate='visible' src={image21} alt="WPenguino" /></Link>
        </div>
        {/* links to the pages */}
        <div className="header2_pages">
       <ul>
        <li><Link to = '/'>HOME</Link></li>
        <li><Link to = '/home2'>HOME2</Link></li>
        <li><Link to = '/pricing'>PRICING</Link></li>
        <li><Link to = '/about'>ABOUT</Link></li>
        <li><Link to = '/features'>FEATURES</Link></li>
        <li><Link to = '/contact'>CONTACT</Link></li>
       </ul>
        </div>
        {/* menu for the off-canvas (for tablet and mobile view) */}
        <div className="header2_menu" onClick={show}>
        <Menu />
        </div>
        {menu ? <div className="header2_off-canvas">
        <div className="header2_off-canvas_header">
        <div className="header2_off-canvas_logo">
        <Link to = '/'>
            <img src={image20} alt="WPenguino" /></Link>
            </div>
            {/* to close the off-canvas */}
            <div className="header2_cross" onClick={show}>
            <X fill = "white"/>
            </div>
        </div>
        {/* links of the pages */}
        <ul>
        <li><Link to = '/'>HOME</Link></li>
        <li><Link to = '/home2'>HOME2</Link></li>
        <li><Link to = '/pricing'>PRICING</Link></li>
        <li><Link to = '/about'>ABOUT</Link></li>
        <li><Link to = '/features'>FEATURES</Link></li>
        <li><Link to = '/contact'>CONTACT</Link></li>
       </ul>
       {/* contact details in off-canvas */}
       <h2>Get In Touch</h2>
<p><Mail />Email<br />work@hitoritech.com</p>
<p><Phone />Phone<br/>447436708081</p>
<p><MapPin />Location<br />London, United Kingdom</p>
       <div className="header2_off-canvas_login">
            <Twitter />
            <Instagram />
            <Facebook />
            <Linkedin />
        </div>
        </div>
        : null}

      
        <div className="header2_login">
            <h2><User />LOGIN</h2>
            <Link to="/pricing"> <button>GET STARTED</button></Link>
        </div>
    </div>
)
}
export default Header2