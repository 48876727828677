import React from "react";
import './Section2.css'
import Img from '../../../../Assets/Images/mask-group-RU1_Hx.svg'
import Check from "feather-icons-react/build/IconComponents/Check";
import { Link } from "react-router-dom";
import {motion} from 'framer-motion'
const Section2 = ()=>{
    const BoxAnimation = {
        hidden: {
            x: 120,
            opacity: 0,
        },
        visible:{
            x:0,
            opacity:1,
            transition: {
                duration: 0.8,
                type:'spring',
                stiffness: 50,
                mass: 0.4,
                damping: 8,
                staggerChildren: 20,
                when: 'beforeChildren',
                delay: 0.5
            }
        }
    }
    const BoxAnimation1 = {
        hidden: {
            y: -75,
            opacity: 0,
        },
        visible:{
            y:0,
            opacity:1,
            transition: {
                duration: 0.8,
                type:'spring',
                stiffness: 50,
                mass: 0.4,
                damping: 8,
                staggerChildren: 20,
                when: 'beforeChildren',
                delay: 0.5
            }
        }
    }
    return(<>
        <div className="section2-main">
            <motion.div variants={BoxAnimation} initial='hidden' whileInView='visible' className="section2-con1">
                <div className="section2-text">
        <h2>How Frusted were you when you imported a CSV file?</h2>
        <h3>Nor the government which has way, the met temple in out of after she and take I it the an of people contract.</h3>
        <h2>Up latter greediness blocks do</h2>
        <p><Check />His vows postage powers blue and first spare some</p>
        <p><Check />His vows postage powers blue and first spare some</p>
        <p><Check />His vows postage powers blue and first spare some</p>
        <h3>Nor the government which has way, the met temple in out of after she and take I it the an of people contract.</h3>
        <Link to='/features'><button>Read Our Basic Guide</button></Link>
                </div>
            
            <div className="section2-img">
               <img src={Img} alt="wpenguino" />
            </div>
            </motion.div>
        </div>

        <div className="section2-main1">
            <motion.div variants={BoxAnimation1} initial='hidden' whileInView='visible' className="section2-con2">
                <div className="section2-text">
        <h2>Move from idea to scheduled pipeline in days minutes</h2>
        <h3>Front with writer's my and themed detailed right accept particularly never I ever either white left familiar of gradually starting many one close he of least</h3>
        <h3>Decided lady helped avoid first customary propitiously idea endeavours, carried when muff forget economics</h3>
        <h3>Quickly timing expected and would on concise or when though, of that as, is the be are annoyed. The as chest have all bed former them. Concise his way had an differences how but better doctor the that</h3>
        <Link to='/features'><button>Read Our Basic Guide</button></Link>
                </div>
            
            <div className="section2-img">
               <img src={Img} alt="wpenguino" />
            </div>
            </motion.div>
        </div>


        <div className="section2-main">
            <motion.div variants={BoxAnimation} initial='hidden' whileInView='visible' className="section2-con1">
                <div className="section2-text">
        <h2>Works perfectly with your every semantics.</h2>
        <h3 style={{fontWeight:'400'}}>Nor the government which has way, the met temple in out of after she and take I it the an of people contract.</h3>
        <h3><b>When divine ran they experience</b></h3>
        <h3><b>Quickly timing expected and would</b></h3>
        <h3>Got o'clock consider waved</h3>
        <Link to='/features'><button>Read Our Basic Guide</button></Link>
                </div>
            
            <div className="section2-img">
               <img src={Img} alt="wpenguino" />
            </div>
            </motion.div>
        </div>
        </>
    )
}
export default Section2